<template>
  <div class="diagnosis-form-table">
    <v-row class="top">
      <v-col cols="2" class="text-center"></v-col>
      <v-col cols="5" class="text-center">
        <h3>{{ $t("examination.diagnosis.rightEye") }}</h3>
      </v-col>
      <v-col cols="5" class="text-center">
        <h3>{{ $t("examination.diagnosis.leftEye") }}</h3>
      </v-col>
    </v-row>
    <v-row v-for="type in DiagnosisType" :key="type">
      <v-col cols="2" style="padding: 0px 75px" class="text-center">
        {{ convertLang(type) }}
      </v-col>
      <v-col v-for="pos of EyeType" :key="pos" cols="5" class="text-center">
        <p class="chip-container">
          <v-chip
            v-for="item in collections[`${type}-${pos}`]"
            v-bind:key="item.id"
            @click:close="deleteDiagnosis(item.id, type, pos)"
            close
            close-icon="mdi-close"
            label
            class="mt-2 mr-2 float-left"
            :color="primaryColor(type)"
            :text-color="primaryTextColor(type)"
          >
            {{ item.textStr }}
          </v-chip>
        </p>
        <v-btn
          v-if="showAddMore(type, pos)"
          icon
          outlined
          color="#0065FF"
          width="20"
          height="20"
          @click="showSearchICDDPopup(type, pos)"
          v-bind:disabled="isFinishExamination === true ? true : false"
        >
          <v-icon dark size="20"> mdi-plus </v-icon>
        </v-btn>
        <span v-show="type === 'Primary' && pos == 1 && descriptionRight" >
          <h4 class="float-left">DESC:</h4>
          <p class="chip-container">
            <v-chip
              label
              class="mt-2 mr-2 float-left"
              color="#ffb703"
              @click="getDiagnosisPrimaryDetail(pos)"
            >
            {{ descriptionRight }}
            </v-chip>
          </p>
        </span>
        <span v-show="type === 'Primary' && pos == 2 && descriptionLeft" >
          <h4 class="float-left">DESC:</h4>
          <p class="chip-container">
            <v-chip
              label
              class="mt-2 mr-2 float-left"
              color="#ffb703"
              @click="getDiagnosisPrimaryDetail(pos)"
            >
              {{ descriptionLeft }}
            </v-chip>
          </p>
        </span>
      </v-col>
    </v-row>
    <SearchICDDPopup
      ref="SearchICDDPopup"
      @onSaveDiagnosisSuccess="handleSaveDiagnosisSuccess"
    />
  </div>
</template>


<script>
import SearchICDDPopup from "@/components/common/Patient/SearchICDDPopup";
import DiagnosisService from "@/services/diagnosis";
import WorkListService from "@/services/work-list";
import EpisodeService from "@/services/episode";
import { EyeType, DiagnosisType } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  name: "DiagnosisPage",
  data() {
    return {
      DiagnosisType,
      EyeType,
      collections: {},
      episodeID: null,
      isFinishExamination: false,
      descriptionLeft: "",
      descriptionRight: "",
    };
  },
  components: {
    SearchICDDPopup,
  },
  async created() {
    this.renderDiagnosis();
    await this.getEpisodeID(this.$route.params.episodeRecordID);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSearchICDDPopup(type, pos) {
      var hiddenItems = this.collections[`${type}-${pos}`];
      hiddenItems = hiddenItems.map((i) => ({ code: i.icdDxCode }));
      this.$refs.SearchICDDPopup.openPopup(hiddenItems, type, pos);
    },
    async getDiagnosisPrimaryDetail(pos) {
      var episodeRecordID = this.$route.params.episodeRecordID;
      const apiResult = await DiagnosisService.GetDiagnosisPrimaryDetail(episodeRecordID, pos);
      this.$refs.SearchICDDPopup.openPopup([], 'Primary', pos, apiResult);
    },
    async renderDiagnosis() {
      const apiResult = await DiagnosisService.SearchListDiagnosis(
        this.$route.params.episodeRecordID,
        1,
        -1 // All
      );
      var { items } = apiResult;
      items.forEach((item) => {
        item.textStr = `${item.icdDxCode} - ${item.icdDxDescription}`;
      });
      for (var type in DiagnosisType) {
        for (var pos in EyeType) {
          this.collections[`${type}-${EyeType[pos]}`] = items.filter(
            (i) =>
              i.diagnosisCode == type && i.diagnosisCodePosition == EyeType[pos]
          );
        }
      }
      for (var pos1 in EyeType) {
        if(pos1 == "Left"){
          this.descriptionLeft = this.collections[`Primary-${EyeType[pos1]}`][0]?.description;
        }
        if(pos1 == "Right"){
          this.descriptionRight = this.collections[`Primary-${EyeType[pos1]}`][0]?.description;
        }
      }
      console.log(">>>>>" + this.descriptionLeft);
      this.$forceUpdate();
    },
    async deleteDiagnosis(diagnosisID, type, pos) {
      var result = await DiagnosisService.DeleteDiagnosisByID(diagnosisID);
      if (result.error) {
        this.showError("This diagnosis cannot be deleted!");
        return;
      }
      var items = this.collections[`${type}-${pos}`];
      var ind = items.findIndex((i) => i.id == diagnosisID);
      items.splice(ind, 1);
      this.collections[`${type}-${pos}`] = items;
      if(pos == 1) {
        this.descriptionRight = "";
      }
      if(pos == 2) {
        this.descriptionLeft ="";
      }
      this.$forceUpdate();
    },
    handleSaveDiagnosisSuccess() {
      this.renderDiagnosis();
    },
    async getEpisodeID(episodeRecordID) {
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get episodeID at the moment! Please try again later."
        );
      }
      this.episodeID = result.episodeID;
      await this.getEpisodeInfo();
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetail(this.episodeID);
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isFinishExamination = true;
      }
    },
    showAddMore(type, pos) {
      if (type == DiagnosisType.Primary) {
        return this.collections[`${type}-${pos}`] &&
          this.collections[`${type}-${pos}`].length == 0
          ? true
          : false;
      } else {
        return true;
      }
    },
    primaryColor(type) {
      return type == DiagnosisType.Primary ? "#0052cc" : "#e0e0e0";
    },
    primaryTextColor(type) {
      return type == DiagnosisType.Primary ? "#ffffff" : "#000000";
    },
    convertLang(text) {
      var val = "";
      if (localStorage.getItem("LOCATE_LANGUAGE") == "vn") {
        switch (text) {
          case "Primary":
            val = i18n.t("examination.diagnosis.primaryDiagnosis");
            break;
          case "Additional":
            val = i18n.t("examination.diagnosis.additionalDiagnosis");
            break;
          case "Suspected":
            val = i18n.t("examination.diagnosis.suspectedDiagnosis");
        }
      } else {
        val = text + " Diagnosis";
      }
      return val;
    },
  },
};
</script>

<style lang="scss">
.diagnosis-form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  width: 70vw;
  min-width: 60vw;
  .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
  p.chip-container {
    width: 100%;
    display: inline-block;
  }
  .v-chip {
    display: inline-table;
    white-space: normal;
    text-align: left;
  }
  p.chip-container {
    width: 100%;
    display: inline-block;
  }
}

.primaryChip {
  background-color: #0052cc;
  color: #ffffff;
}
</style>
